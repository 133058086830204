import React from "react";
import PropTypes from "prop-types";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./DealTimer.scss";
import { BRANDS, OFFER_PRICE_TYPES } from "app/constants";
import SaveUpTo from "app/pages/FicheProduit/components/Deal/SaveUpTo";
import From from "app/pages/FicheProduit/components/Deal/From";
import { FormattedMessage } from "react-intl";
import { brandPropTypes, fromPriceTypeShape } from "app/utils/propTypes";
import Timer from "app/pages/.shared/Timer";

class DealTimer extends React.PureComponent {
	render() {
		const {
			hour,
			day,
			value,
			duration,
			price,
			publicPrice,
			type,
			isRental,
			brand = "",
		} = this.props;

		let priceType = false;

		switch (type) {
			case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_SAVE_UP_TO:
				priceType =
					brand === BRANDS.VE || brand === BRANDS.VP ? (
						<From
							includeOfferWithFlight={false}
							value={price}
							priceSuffix={
								isRental ? (
									<FormattedMessage id="general.amount.per.accommodation.suffix.label.short" />
								) : (
									<FormattedMessage id="general.amount.per.person.suffix.label.short" />
								)
							}
							pricePrefix={<FormattedMessage id="general.from.label" />}
							duration={duration}
							publicPrice={publicPrice}
							publicPricePrefix={<FormattedMessage id="general.instead.of.price" />}
							publicPriceSuffix={
								isRental ? (
									<FormattedMessage id="general.amount.per.accommodation.suffix.label.short" />
								) : (
									<FormattedMessage id="general.amount.per.person.suffix.label.short" />
								)
							}
							offerPriceType={type}
						/>
					) : (
						<SaveUpTo value={value} />
					);
				break;
			case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM:
			case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM_WITH_FLIGHT:
				priceType = (
					<From
						includeOfferWithFlight={false} // on ne veut pas afficher les infos de vols en dessous du prix
						value={value}
						pricePrefix={<FormattedMessage id="general.from.label" />}
						priceSuffix={
							isRental ? (
								<FormattedMessage id="general.amount.per.accommodation.suffix.label.short" />
							) : (
								<FormattedMessage id="general.amount.per.person.suffix.label.short" />
							)
						}
						duration={duration}
						publicPrice={publicPrice}
						publicPricePrefix={<FormattedMessage id="general.instead.of.price" />}
						offerPriceType={type}
					/>
				);
				break;
			case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_EXCLUSIVE:
				priceType = (
					<div className="deal-timer__exclusive">
						<FormattedMessage id="general.exclusive.label" />
					</div>
				);
				break;
			default:
				break;
		}

		const isDealExpired = hour === 0 && day === 0;

		const dealNode = !isDealExpired ? (
			<div className="deal-timer__content">
				<div className="deal-timer__price-type">{priceType}</div>
				<div className="deal-timer__timer">
					<Timer
						hour={hour}
						day={day}
						suffix={<FormattedMessage id="timer.left.label" />}
					/>
				</div>
			</div>
		) : (
			<div className="deal-timer__status">
				<FormattedMessage id="product.deal.expired.label" />
			</div>
		);

		return <div className="deal-timer">{dealNode}</div>;
	}
}

DealTimer.propTypes = {
	duration: PropTypes.number,
	type: fromPriceTypeShape,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	price: PropTypes.number,
	publicPrice: PropTypes.number,
	hour: PropTypes.number,
	day: PropTypes.number,
	isRental: PropTypes.bool,
	brand: brandPropTypes,
};

export default registerStyle(style)(DealTimer);
