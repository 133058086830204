import React from "react";
import PropTypes from "prop-types";
import "./CreditCardPictos.scss";

const CreditCardPictos = ({ creditCardCodes }) => {
	return (
		<div className="credit-card-pictos">
			{creditCardCodes.map(code => (
				<i
					key={code}
					className={`icon icon--${code.toLowerCase()}-colorful credit-card-pictos__icon`}
				/>
			))}
		</div>
	);
};

CreditCardPictos.defaultProps = {
	creditCardCodes: [],
};

CreditCardPictos.propTypes = {
	creditCardCodes: PropTypes.array,
};

export default React.memo(CreditCardPictos);
