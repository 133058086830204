import React from "react";
import PropTypes from "prop-types";
import "./CurrentProductsOverview.scss";
import { FormattedMessage } from "react-intl";
import ProductList from "app/pages/Listing/components/ProductList";
import { productShape } from "app/utils/propTypes";

const CurrentProductsOverview = ({ products }) => {
	return (
		<div className="current-products-overview">
			<h1 className="current-products-overview__title">
				<FormattedMessage id="home.products.title" />
			</h1>
			<ProductList products={products} />
		</div>
	);
};

CurrentProductsOverview.propTypes = {
	products: PropTypes.arrayOf(productShape),
};

CurrentProductsOverview.defaultProps = {
	products: [],
};

export default CurrentProductsOverview;
