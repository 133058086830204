/* eslint-disable react/no-unused-prop-types */
import React from "react";
import PropTypes from "prop-types";

export class ConditionalComponent extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return React.isValidElement(this.props.children) ? (
			this.props.children
		) : (
			<div> {this.props.children} </div>
		);
	}
}

ConditionalComponent.propTypes = {
	showIf: PropTypes.func.isRequired,
};
