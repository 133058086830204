import React from "react";
import { FormattedMessage } from "react-intl";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./copy-clipboard-button.scss";
import PropTypes from "prop-types";
import IconSuccessLink from "app/pages/.shared/static/icons/IconSuccessLink";
import IconLink from "app/pages/.shared/static/icons/IconLink";

const INVISIBLE_STYLE = {
	background: "transparent",
	border: "none",
	outline: "none",
	boxShadow: "none",
	padding: 0,
	opacity: 0,
	height: "1px",
	width: "1px",
};

export class CopyToClipboardButton extends React.PureComponent {
	constructor() {
		super();
		this.state = {
			enable: false,
			copied: false,
		};
		this.copyToClipboard = this.copyToClipboard.bind(this);
	}

	componentDidMount() {
		if (document.queryCommandSupported("copy")) {
			this.setState({
				enable: true,
			});
		}
	}

	copyToClipboard() {
		try {
			// @see https://developers.google.com/web/updates/2015/04/cut-and-copy-commands
			// @see http://www.javascriptkit.com/javatutors/copytoclipboard.shtml
			const copyTextarea = this.copyTextArea;
			const range = document.createRange();
			range.selectNodeContents(copyTextarea);
			const selection = window.getSelection();
			selection.removeAllRanges();
			selection.addRange(range);

			let copyResult = document.execCommand("copy");

			this.setState(
				{
					copied: copyResult,
				},
				() => {
					setTimeout(() => {
						this.setState(
							{
								copied: false,
							},
							() => {
								if (typeof this.props.onReset === "function") {
									this.props.onReset();
								}
							}
						);
					}, 2000);
				}
			);

			if (typeof this.props.onCopySuccess === "function" && copyResult) {
				this.props.onCopySuccess(this.props.text);
			}
		} catch (err) {
			this.setState({
				copied: false,
			});
		}
	}

	render() {
		const { text, label } = this.props;
		const buttonLabel = this.state.copied ? (
			<div className="copy-clipboard-button__label" style={{ display: "block" }}>
				<FormattedMessage id="share.clipboard.button.success.label" />
			</div>
		) : (
			<div className="copy-clipboard-button__label">{label}</div>
		);
		const icon = this.state.copied ? (
			<IconSuccessLink width={20} height={20} />
		) : (
			<IconLink width={20} height={20} />
		);

		return this.state.enable ? (
			<div className="copy-clipboard-button" onClick={this.copyToClipboard}>
				<div
					ref={input => {
						this.copyTextArea = input;
					}}
					style={INVISIBLE_STYLE}
				>
					{text}
				</div>
				<span className="copy-clipboard-button__icon">{icon}</span>
				{buttonLabel}
			</div>
		) : (
			false
		);
	}
}

CopyToClipboardButton.propTypes = {
	onCopySuccess: PropTypes.func,
	onReset: PropTypes.func,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default registerStyle(style)(CopyToClipboardButton);
