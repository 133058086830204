import React from "react";
import { FormattedMessage } from "react-intl";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./cookie-policy-footer.scss";
import Button from "app/pages/.shared/form/Button";
import PropTypes from "prop-types";

class CookiePolicyFooter extends React.PureComponent {
	constructor() {
		super();
		this.hideFooter = this.hideFooter.bind(this);
	}

	hideFooter() {
		this.props.acceptCookyPolicy();
	}

	render() {
		const {
			showCookieFooter = false,
			confidentialiteDocumentName,
			showButton = false,
		} = this.props;

		return (
			showCookieFooter && (
				<div className="cookie-policy-footer">
					<div className="cookie-policy-footer__content">
						<FormattedMessage
							values={{
								linkToCGV: (
									<a
										className="relative-link"
										rel="noopener noreferrer"
										target="_blank"
										href={confidentialiteDocumentName}
									>
										<FormattedMessage id="cookie.policy.cgv.link" />
									</a>
								),
							}}
							id="cookie.policy.footer.message"
						/>
						{showButton ? (
							<div className="cookie-policy-footer__cta">
								<Button design="primary" onClick={this.hideFooter}>
									<FormattedMessage id="cookie.policy.footer.accept" />
								</Button>
							</div>
						) : (
							<i
								className="icon icon--circle-cross-white"
								onClick={this.hideFooter}
							/>
						)}
					</div>
				</div>
			)
		);
	}
}

CookiePolicyFooter.propTypes = {
	acceptCookyPolicy: PropTypes.func,
	showCookieFooter: PropTypes.bool,
	confidentialiteDocumentName: PropTypes.string,
	showButton: PropTypes.bool,
};

export default registerStyle(style)(CookiePolicyFooter);
