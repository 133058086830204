import PropTypes from "prop-types";
import React from "react";
import "./Category.scss";
import IconCategoryStar from "app/pages/.shared/icons/IconCategoryStar";
import IconCategoryHalfStar from "app/pages/.shared/icons/IconCategoryHalfStar";

const Category = ({ category }) => {
	const stars = [];

	if (category) {
		for (let i = 0; i < category - 1; i++) {
			stars.push(<IconCategoryStar key={i} height="0.8em" width="0.8em" />);
		}

		if (category % 1 === 0.5) {
			stars.push(<IconCategoryHalfStar key={99} height="0.8em" width="0.8em" />);
		} else {
			stars.push(<IconCategoryStar key={99} height="0.8em" width="0.8em" />);
		}
	}

	return <span className="category">{stars}</span>;
};

Category.propTypes = {
	category: PropTypes.number,
};

export default React.memo(Category);
