import PropTypes from "prop-types";
import React from "react";
import "./CategoryTrident.scss";

const CategoryTrident = ({ category }) => {
	const stars = category && (
		<span className={`icon icon--trident-${category} category-trident__item`} />
	);

	return <span className="category-trident">{stars}</span>;
};

CategoryTrident.propTypes = {
	category: PropTypes.number,
};

export default React.memo(CategoryTrident);
