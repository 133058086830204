import React from "react";
import DealTimer from "app/pages/.shared/Deal/DealTimer/DealTimer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fromPriceTypeShape } from "app/utils/propTypes";

class DealTimerContainer extends React.Component {
	render() {
		return <DealTimer {...this.props} />;
	}
}

DealTimerContainer.propTypes = {
	duration: PropTypes.number,
	type: fromPriceTypeShape,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	price: PropTypes.number,
	publicPrice: PropTypes.number,
	hour: PropTypes.number,
	day: PropTypes.number,
	isRental: PropTypes.bool,
};

const mapStateToProps = state => {
	return {
		brand: state.brand.code,
	};
};

export default connect(mapStateToProps)(DealTimerContainer);
