import { connect } from "react-redux";
import { acceptCookyPolicy } from "app/pages/.shared/CookiePolicyFooter/cookiePolicyActionCreators";
import { bindActionCreators } from "redux";
import { BRANDS } from "app/constants";
import CookiePolicyFooter from "app/pages/.shared/CookiePolicyFooter/CookiePolicyFooter";

const mapStateToProps = state => {
	const brand = state.brand.code;
	const shop = state.shop;
	return {
		showCookieFooter:
			typeof window !== "undefined" &&
			shop.slice(3, 5) !== "CH" && // on masque si ce n'est pas un pays de lUE
			!state.isCookiePolicyAccepted &&
			!state.auth.token &&
			!state.navigation.isInApp,
		showButton: brand === BRANDS.LR,
		confidentialiteDocumentName: state.documents.confidentialite,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ acceptCookyPolicy }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CookiePolicyFooter);
