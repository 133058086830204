import React from "react";
import IconABTACertification from "./IconABTACertification";
import IconLogoAtol from "app/pages/.shared/static/icons/IconLogoAtol";
import "./CondensedBookWithConfidence.scss";

const CondensedBookWithConfidence = () => {
	return (
		<div className="book-with-confidence-booking-page">
			<div className="book-with-confidence-booking-page__text-container">
				<p>Book with confidence</p>
				<p>ATOL & ABTA protected</p>
			</div>
			<div className="book-with-confidence-booking-page__logo-container">
				<IconLogoAtol height={40} width={40} />
				<span className="separator" />
				<IconABTACertification height={50} width={80} withColor={true} />
			</div>
		</div>
	);
};

export default React.memo(CondensedBookWithConfidence);
