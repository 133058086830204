import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import DateSelectorItem from "./DateSelectorItem";
import range from "lodash/range";
import { FormattedDate, FormattedMessage } from "react-intl";
import "./date-selector.scss";
import { checkDateValidity, getDaysInMonth } from "app/utils/utils";
import IconLeft from "app/pages/.shared/static/icons/IconLeft";

const SELECTORS = {
	DAY: 0,
	MONTH: 1,
	YEAR: 2,
};

class DateSelector extends React.PureComponent {
	constructor() {
		super();
		this.state = {
			visibleSelector: SELECTORS.DAY,
			selectedDay: undefined,
			selectedMonth: undefined,
			selectedYear: undefined,
		};
		this.onDaySelect = this.onDaySelect.bind(this);
		this.onMonthSelect = this.onMonthSelect.bind(this);
		this.onYearSelect = this.onYearSelect.bind(this);
		this.handleLeftClick = this.handleLeftClick.bind(this);
		this.finalizeMonth = this.finalizeMonth.bind(this);
		this.finalize = this.finalize.bind(this);
	}

	onDaySelect(day) {
		this.setState(
			{
				visibleSelector: SELECTORS.MONTH,
				selectedDay: day,
			},
			() => {
				if (typeof this.props.onChange === "function") {
					this.props.onChange({ day });
				}
			}
		);
	}

	onMonthSelect(month) {
		this.setState(
			{
				visibleSelector: SELECTORS.YEAR,
				selectedMonth: month,
			},
			() => {
				this.finalizeMonth();
			}
		);
	}

	onYearSelect(year) {
		this.setState(
			{
				selectedYear: year,
			},
			() => {
				this.finalize();
			}
		);
	}

	finalizeMonth() {
		if (this.state.selectedMonth && this.state.selectedDay) {
			if (this.state.selectedMonth === 2 && this.state.selectedDay <= 29) {
				if (typeof this.props.onChange === "function") {
					this.props.onChange({
						day: this.state.selectedDay,
						month: this.state.selectedMonth - 1,
					});
				}
			} else {
				const maxDayInMonth = getDaysInMonth(this.state.selectedMonth);

				if (this.state.selectedDay > maxDayInMonth) {
					this.setState(
						{
							visibleSelector: SELECTORS.DAY,
							selectedDay: undefined,
							selectedMonth: undefined,
							selectedYear: undefined,
						},
						() => {
							if (typeof this.props.onChange === "function") {
								this.props.onChange({
									day: undefined,
									month: undefined,
									year: undefined,
								});
							}
						}
					);
				} else if (typeof this.props.onChange === "function") {
					this.props.onChange({
						day: this.state.selectedDay,
						month: this.state.selectedMonth - 1,
					});
				}
			}
		}
	}

	finalize() {
		if (this.state.selectedYear && this.state.selectedMonth && this.state.selectedDay) {
			const isDateValid = checkDateValidity(
				this.state.selectedYear,
				this.state.selectedMonth,
				this.state.selectedDay
			);

			if (isDateValid) {
				if (typeof this.props.onChange === "function") {
					this.props.onChange({
						day: this.state.selectedDay,
						month: this.state.selectedMonth - 1,
						year: this.state.selectedYear,
					});
				}
			} else {
				this.setState(
					{
						visibleSelector: SELECTORS.DAY,
						selectedDay: undefined,
						selectedMonth: undefined,
						selectedYear: undefined,
					},
					() => {
						if (typeof this.props.onChange === "function") {
							this.props.onChange({
								day: undefined,
								month: undefined,
								year: undefined,
							});
						}
					}
				);
			}
		}
	}

	renderMonth(month) {
		if (month >= 1) {
			const thisYear = new Date().getFullYear();
			return (
				<FormattedDate value={new Date(thisYear, month - 1, 15, 15, 0, 0)} month="long" />
			);
		}
		return false;
	}

	handleLeftClick() {
		if (this.state.visibleSelector === SELECTORS.MONTH) {
			this.setState({
				visibleSelector: SELECTORS.DAY,
				selectedMonth: undefined,
			});
		} else if (this.state.visibleSelector === SELECTORS.YEAR) {
			this.setState({
				visibleSelector: SELECTORS.MONTH,
			});
		}
	}

	render() {
		const { maxYear = new Date().getFullYear(), minYear = 1900 } = this.props;

		let title;

		if (this.state.visibleSelector === SELECTORS.DAY) {
			title = <FormattedMessage id="date.selector.choose.day.label" />;
		} else if (this.state.visibleSelector === SELECTORS.MONTH) {
			title = <FormattedMessage id="date.selector.choose.month.label" />;
		} else if (this.state.visibleSelector === SELECTORS.YEAR) {
			title = <FormattedMessage id="date.selector.choose.year.label" />;
		}

		const dayClass = classNames({
			"date-selector__selector": true,
			"date-selector__selector--visible": this.state.visibleSelector === SELECTORS.DAY,
		});

		const monthClass = classNames({
			"date-selector__selector date-selector__selector--month": true,
			"date-selector__selector--visible": this.state.visibleSelector === SELECTORS.MONTH,
		});

		const yearClass = classNames({
			"date-selector__selector date-selector__selector--year": true,
			"date-selector__selector--visible": this.state.visibleSelector === SELECTORS.YEAR,
		});

		return (
			<div className="date-selector">
				<div className="date-selector__actions">
					{this.state.visibleSelector !== SELECTORS.DAY ? (
						<IconLeft width={20} height={20} onClick={this.handleLeftClick} />
					) : (
						<span style={{ width: "20px" }} />
					)}
					<div> {title} </div>
					<span style={{ width: "20px" }} />
				</div>
				<div className="date-selector__body">
					<div className={dayClass}>
						{range(1, 32).map(day => {
							return (
								<DateSelectorItem
									isActive={this.state.selectedDay === day}
									key={day}
									value={day}
									onClick={this.onDaySelect}
									label={day}
									data-cy={`date-input-day-${day}`}
								/>
							);
						})}
					</div>

					<div className={monthClass}>
						{range(1, 13).map(month => {
							return (
								<DateSelectorItem
									isActive={this.state.selectedMonth === month}
									key={month}
									value={month}
									onClick={this.onMonthSelect}
									label={this.renderMonth}
									data-cy={`date-input-month-${month}`}
								/>
							);
						})}
					</div>

					<div className={yearClass}>
						{range(maxYear, minYear).map(year => {
							return (
								<DateSelectorItem
									isActive={this.state.selectedYear === year}
									key={year}
									value={year}
									onClick={this.onYearSelect}
									label={year}
									data-cy={`date-input-year-${year}`}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

DateSelector.propTypes = {
	onChange: PropTypes.func,
	maxYear: PropTypes.number,
	minYear: PropTypes.number,
};

export default DateSelector;
