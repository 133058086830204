import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
export class ConditionalComponents extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { defaultComponent = false } = this.props;

		const conditionalComponent = React.Children.toArray(this.props.children).find(child => {
			return typeof get(child, "props.showIf") === "function" && child.props.showIf();
		});

		return conditionalComponent || defaultComponent;
	}
}

ConditionalComponents.propTypes = {
	defaultComponent: PropTypes.element,
};
